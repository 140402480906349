import React, { Children } from "react";
import PageContainer from "../PageContainer";
import {
    PageWrapper,
    Err,
    ErrCode,
    ErrTitle,
    ErrMsg,
    ButtonWrapper,
} from "./styles";
import Button from "../Button";

const NotFound = () => {
    return (
        <PageWrapper>
            <Err>Error Page</Err>
            <ErrCode>404</ErrCode>
            <ErrTitle>PAGE CAN NOT BE FOUND</ErrTitle>
            <ErrMsg>
                The page you are looking for does not exist. It may have been
                moved, or removed altogether. Perhaps you can return back to the
                site's homepage and see if you can find what you are looking
                for.
            </ErrMsg>
            <ButtonWrapper>
                <Button to="/" title="BACK TO HOMEPAGE">
                    BACK TO HOMEPAGE
                </Button>
            </ButtonWrapper>
        </PageWrapper>
    );
};

export default NotFound;
